// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Taskgroup__main
{
    width: 100%;
        height: 100vh;
        background-color: #000000;
        overflow: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
   
     
}
.Taskgroup__main::-webkit-scrollbar {
    display: none;
  }`, "",{"version":3,"sources":["webpack://./src/pages/TaskGroup/TaskGroup.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;QACP,aAAa;QACb,yBAAyB;QACzB,gBAAgB;QAChB,wBAAwB;QACxB,qBAAqB;;;AAG7B;AACA;IACI,aAAa;EACf","sourcesContent":[".Taskgroup__main\n{\n    width: 100%;\n        height: 100vh;\n        background-color: #000000;\n        overflow: hidden;\n        -ms-overflow-style: none;\n        scrollbar-width: none;\n   \n     \n}\n.Taskgroup__main::-webkit-scrollbar {\n    display: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
