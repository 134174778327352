// stateManager.js
class StateManager {
    constructor() {
      this.actref = null;
    }
  
    setActref(value) {
      this.actref = value;
    }
  
    getActref() {
      return this.actref;
    }
  }
  
  const instance = new StateManager();
  export default instance;
  