// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empity-page{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    height: 100vh;
    background-color: black;
  
}`, "",{"version":3,"sources":["webpack://./src/pages/emptyPage/index.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,uBAAuB;;AAE3B","sourcesContent":[".empity-page{\n    width:100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: gray;\n    height: 100vh;\n    background-color: black;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
