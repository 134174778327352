import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';

import "./index.css"

const Loading = ({disp}) => {
  return (
    <div className='loading' style={disp?{display:"flex"}:{display:"none"}}>
        {/* <Box sx={{ display: 'flex' }}> */}
            <CircularProgress />
        {/* </Box> */}
    </div>
  );
}

export default Loading