import React from "react";


import "./index.css"

const EmptyPage = () =>{
    return (
        <div className="empity-page">
            <h1>This Page is Empty</h1>
        </div>
    )
}

export default EmptyPage;