import "./RaffleTicket.css";
import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { getShopRaffle, updateRaffle } from "../../apis/tasks";
const RaffleTicket = () => {
  const rows1 = [
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      aaction: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
  ];

  const [updateData, setUpdateData] = useState();
  const [rows, setRows] = useState(rows1);
  const [datas, setDatas] = useState();

  const handleCheckbox = (index) => {
    const updatedRows = rows.map((row, i) =>
      i === index
        ? { ...row, action: row.action === "check" ? "cancel" : "check" }
        : row
    );
    setRows(updatedRows);
  };

  async function getData() {
    const res = await getShopRaffle();
    console.log("this is response", res);
    setDatas(res?.data);
  }
  React.useEffect(() => {
    getData();
  }, [updateData]);

  function formatDateTime(isoDate) {
    const date = new Date(isoDate);

    // Format date as MM/DD/YYYY
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    // Format time as HH:MM:SS AM/PM
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // AM/PM format
    });

    return `${formattedDate} ${formattedTime}`;
  }

  async function approvveTranscation(reddem) {
    try {
      const data = {
        reddemId: reddem, // Example value for reddemId
        status: true, // Example value for status
      };
      const res = await updateRaffle(data);
      console.log(res);
      setUpdateData(res);
    } catch (error) {
      console.log(error);
    }
  }
  async function decline(reddem) {
    try {
      const data = {
        reddemId: reddem, // Example value for reddemId
        status: false, // Example value for status
      };
      const res = await updateRaffle(data);
      console.log(res);
      setUpdateData(res);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="Raffleticket__main">
      <TableContainer
        component={Paper}
        className="tableContainer__"
        sx={{
          backgroundColor: "#090909",
          border: "2px solid #121212",
          borderRadius: "10px",
          padding: "30px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="custom table">
          <TableHead>
            <TableRow>
              <TableCell className="Raffleticket__tableheadtext">
                User Image
              </TableCell>
              <TableCell className="Raffleticket__tableheadtext">
                Telegram Username
              </TableCell>
              <TableCell className="Raffleticket__tableheadtext">
                Date
              </TableCell>
              <TableCell className="Raffleticket__tableheadtext">
                Points
              </TableCell>
              <TableCell className="Raffleticket__tableheadtext">
                Customer ID
              </TableCell>
              <TableCell className="Raffleticket__tableheadtext">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datas?.map((row, index) => {
              if (row?.redeemType == "Raffle")
                return (
                  <TableRow key={index}>
                    <TableCell style={{ borderBottom: "1px solid #121212" }}>
                      <Avatar
                        alt={row.username}
                        src={row?.usersId?.profilePic}
                      />
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: "1px solid #121212" }}
                      className="Raffleticket__rowText"
                    >
                      {row?.usersId?.userName}
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: "1px solid #121212" }}
                      className="Raffleticket__rowText1"
                    >
                      {formatDateTime(row?.createdAt)}
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: "1px solid #121212" }}
                      className="Raffleticket__rowText1"
                    >
                      {row?.bePoints}
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: "1px solid #121212" }}
                      className="Raffleticket__rowText1"
                    >
                      {row?.usersId?.beUserId}
                    </TableCell>
                    {row?.approved ? (
                      <TableCell
                        style={{
                          borderBottom: "1px solid #121212",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <img src="/Images/tick.svg" alt="Tick" />
                      </TableCell>
                    ) : row?.approved == false ? (
                      <TableCell
                        style={{
                          borderBottom: "1px solid #121212",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <img src="/Images/cancel.svg" alt="Cancel" />
                      </TableCell>
                    ) : (
                      <TableCell
                        style={{
                          borderBottom: "1px solid #121212",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <img
                          onClick={() => {
                            approvveTranscation(row?._id);
                          }}
                          src="/Images/tick.svg"
                          alt="Tick"
                        />

                        <img
                          onClick={() => {
                            decline(row?._id);
                          }}
                          src="/Images/cancel.svg"
                          alt="Cancel"
                        />
                      </TableCell>
                    )}
                 
                  </TableRow>
                );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RaffleTicket;
