// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-h1 {
  /* margin-left:50px */
  margin: 0px !important;
}
/* DropdownStyles.css */
.button-week {
  display: flex;
  padding: 14px 17px;
  border-radius: 60px !important;
  margin-right: 10px !important;
  height: 45px !important;
  width: 140px !important;
  background: linear-gradient(#090909, #090909) padding-box, linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%) border-box !important;
  border: 1px solid transparent !important;
  font-family: 'Montserrat', sans-serif !important;
  color: #FFFFFF !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  justify-content: center;

}
.dropdown-toggle {
  display: flex !important;
  align-items: center !important;
  background: transparent !important;
  white-space: nowrap !important;
  border: none !important;
  gap:14px !important;
}
@media (max-width: 430px) {
  .button-week {
    display: none;
  }
  .image-week {
    margin-right: 12px;
  }
  .header-h1 {
    /* margin-left:25px */
  }
}
@media (min-width: 431px) {
  .image-week {
    display: none;
  }
}
@media (max-width: 1070px) {
    .header-h1 {
    padding-left: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/TaskPage/header.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,sBAAsB;AACxB;AACA,uBAAuB;AACvB;EACE,aAAa;EACb,kBAAkB;EAClB,8BAA8B;EAC9B,6BAA6B;EAC7B,uBAAuB;EACvB,uBAAuB;EACvB,yIAAyI;EACzI,wCAAwC;EACxC,gDAAgD;EAChD,yBAAyB;EACzB,2BAA2B;EAC3B,0BAA0B;EAC1B,uBAAuB;;AAEzB;AACA;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,kCAAkC;EAClC,8BAA8B;EAC9B,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,kBAAkB;EACpB;EACA;IACE,qBAAqB;EACvB;AACF;AACA;EACE;IACE,aAAa;EACf;AACF;AACA;IACI;IACA,kBAAkB;EACpB;AACF","sourcesContent":[".header-h1 {\n  /* margin-left:50px */\n  margin: 0px !important;\n}\n/* DropdownStyles.css */\n.button-week {\n  display: flex;\n  padding: 14px 17px;\n  border-radius: 60px !important;\n  margin-right: 10px !important;\n  height: 45px !important;\n  width: 140px !important;\n  background: linear-gradient(#090909, #090909) padding-box, linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%) border-box !important;\n  border: 1px solid transparent !important;\n  font-family: 'Montserrat', sans-serif !important;\n  color: #FFFFFF !important;\n  font-weight: 600 !important;\n  font-size: 14px !important;\n  justify-content: center;\n\n}\n.dropdown-toggle {\n  display: flex !important;\n  align-items: center !important;\n  background: transparent !important;\n  white-space: nowrap !important;\n  border: none !important;\n  gap:14px !important;\n}\n@media (max-width: 430px) {\n  .button-week {\n    display: none;\n  }\n  .image-week {\n    margin-right: 12px;\n  }\n  .header-h1 {\n    /* margin-left:25px */\n  }\n}\n@media (min-width: 431px) {\n  .image-week {\n    display: none;\n  }\n}\n@media (max-width: 1070px) {\n    .header-h1 {\n    padding-left: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
