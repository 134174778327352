// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Raffleticket__main
{
    display: flex;
    background-color: #000000;
    padding-top: 50px;
    height: 100vh;
}
.Raffleticket__tableheadtext
{
    border-bottom: 1px solid #121212 !important;
    font-family: Montserrat !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 15px !important;
letter-spacing: 0.10000000149011612px !important;
text-align: left !important;
color: #FFFFFF80 !important;
padding: 0px 20px 20px 0px !important;
}
.Raffleticket__rowText
{
    font-family: Montserrat !important;
font-size: 16px !important;
font-weight: 600 !important;
line-height: 24px !important;
text-align: left !important;
color: #FFFFFF !important;
}
.Raffleticket__rowText1
{
    font-family: Montserrat !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 21px !important;
text-align: left !important;
color: #FFFFFF !important;
}
.Raffleticket__rowText2
{
    font-family: Montserrat !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 21px !important;
text-align: left !important;
letter-spacing: -0.001em !important;
color: #FFFFFF !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/RaffleTicket/RaffleTicket.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,yBAAyB;IACzB,iBAAiB;IACjB,aAAa;AACjB;AACA;;IAEI,2CAA2C;IAC3C,kCAAkC;AACtC,0BAA0B;AAC1B,2BAA2B;AAC3B,4BAA4B;AAC5B,gDAAgD;AAChD,2BAA2B;AAC3B,2BAA2B;AAC3B,qCAAqC;AACrC;AACA;;IAEI,kCAAkC;AACtC,0BAA0B;AAC1B,2BAA2B;AAC3B,4BAA4B;AAC5B,2BAA2B;AAC3B,yBAAyB;AACzB;AACA;;IAEI,kCAAkC;AACtC,0BAA0B;AAC1B,2BAA2B;AAC3B,4BAA4B;AAC5B,2BAA2B;AAC3B,yBAAyB;AACzB;AACA;;IAEI,kCAAkC;AACtC,0BAA0B;AAC1B,2BAA2B;AAC3B,4BAA4B;AAC5B,2BAA2B;AAC3B,mCAAmC;AACnC,yBAAyB;AACzB","sourcesContent":[".Raffleticket__main\n{\n    display: flex;\n    background-color: #000000;\n    padding-top: 50px;\n    height: 100vh;\n}\n.Raffleticket__tableheadtext\n{\n    border-bottom: 1px solid #121212 !important;\n    font-family: Montserrat !important;\nfont-size: 14px !important;\nfont-weight: 500 !important;\nline-height: 15px !important;\nletter-spacing: 0.10000000149011612px !important;\ntext-align: left !important;\ncolor: #FFFFFF80 !important;\npadding: 0px 20px 20px 0px !important;\n}\n.Raffleticket__rowText\n{\n    font-family: Montserrat !important;\nfont-size: 16px !important;\nfont-weight: 600 !important;\nline-height: 24px !important;\ntext-align: left !important;\ncolor: #FFFFFF !important;\n}\n.Raffleticket__rowText1\n{\n    font-family: Montserrat !important;\nfont-size: 14px !important;\nfont-weight: 400 !important;\nline-height: 21px !important;\ntext-align: left !important;\ncolor: #FFFFFF !important;\n}\n.Raffleticket__rowText2\n{\n    font-family: Montserrat !important;\nfont-size: 14px !important;\nfont-weight: 500 !important;\nline-height: 21px !important;\ntext-align: left !important;\nletter-spacing: -0.001em !important;\ncolor: #FFFFFF !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
