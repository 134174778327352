// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr-code-page{
    width: 100%;
    display: flex;
    padding-top: 50px;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
}

.qr-code-page img{
    width: 300px;
}

.qr-code-page h2{
    margin-top: 50px;
}
.qr-code-page p{
    margin: 0px;
    font-size: 12px;
    font-weight: bold;
}

.qr-code-page input[type=text]{
    width: 70%;
    margin-top: 30px;
    height: 32px;
    border: 1px solid #e1ecff;
    outline: none;
    text-align: center;
}

.qr-code-page .MuiButton-contained{
    margin-top: 0px !important;
    margin-left: 20px;
    background-color: #3350ce;
    border-radius: 20px;
    padding: 10px;
    font-size: 12px;
    text-transform: none;
    font-weight: bold;
}
.qr-code-page .MuiButton-outlined{
    border-radius: 20px;
    font-size: 12px;
    text-transform: none;
    padding: 10px;
    width: 100px;
    font-weight: bold;
}

.qr-code-page .button-group{
    margin-top: 20px;
    width: 80%;
    display: flex;
    flex-direction: row-reverse!important;
}`, "",{"version":3,"sources":["webpack://./src/pages/QRcodePage/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,6BAA6B;IAC7B,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,oBAAoB;IACpB,iBAAiB;AACrB;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,oBAAoB;IACpB,aAAa;IACb,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,aAAa;IACb,qCAAqC;AACzC","sourcesContent":[".qr-code-page{\n    width: 100%;\n    display: flex;\n    padding-top: 50px;\n    /* justify-content: center; */\n    flex-direction: column;\n    align-items: center;\n}\n\n.qr-code-page img{\n    width: 300px;\n}\n\n.qr-code-page h2{\n    margin-top: 50px;\n}\n.qr-code-page p{\n    margin: 0px;\n    font-size: 12px;\n    font-weight: bold;\n}\n\n.qr-code-page input[type=text]{\n    width: 70%;\n    margin-top: 30px;\n    height: 32px;\n    border: 1px solid #e1ecff;\n    outline: none;\n    text-align: center;\n}\n\n.qr-code-page .MuiButton-contained{\n    margin-top: 0px !important;\n    margin-left: 20px;\n    background-color: #3350ce;\n    border-radius: 20px;\n    padding: 10px;\n    font-size: 12px;\n    text-transform: none;\n    font-weight: bold;\n}\n.qr-code-page .MuiButton-outlined{\n    border-radius: 20px;\n    font-size: 12px;\n    text-transform: none;\n    padding: 10px;\n    width: 100px;\n    font-weight: bold;\n}\n\n.qr-code-page .button-group{\n    margin-top: 20px;\n    width: 80%;\n    display: flex;\n    flex-direction: row-reverse!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
