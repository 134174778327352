import React from 'react'

import config from '../config'

export const addNewTask = async (data) =>{
    let url = config.main_url + "tasks";
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then((rsp)=>rsp.json()).then((obj)=>{
        return obj;
    })
    return response;
}



export const getTasks = async(task_type, is_live)=>{
    let url = config.main_url + `tasks/${task_type}/${is_live}`;
    console.log(url)
    const response = await fetch(url, {
        method: 'Get',
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((rsp)=>rsp.json()).then((obj)=>{
        return obj;
    })
    return response;
}

export const deleteTask = async(taskId)=>{
    let url = config.main_url + `tasks/${taskId}`;
    const response = await fetch(url, {
        method: 'Delete',
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((rsp)=>rsp.json()).then((obj)=>{
        return obj;
    })
    return response;
}
export const deleteTaskGroup = async(taskId)=>{
    let url = config.main_url + `tasks/deleteTaskGroup/${taskId}`;
    const response = await fetch(url, {
        method: 'Delete',
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((rsp)=>rsp.json()).then((obj)=>{
        return obj;
    })
    return response;
}

export const updateTask = async(data, taskId)=>{
    let url = config.main_url + `tasks/${taskId}`;
    const response = await fetch(url, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then((rsp)=>rsp.json()).then((obj)=>{
        return obj;
    })
    return response;
}

export const getShopRaffle = async()=>{
    let url = config.main_url + `tasks/getUserRedeem`;
    const response = await fetch(url, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((rsp)=>rsp.json()).then((obj)=>{
        return obj;
    })
    return response;
}

export const updateRaffle = async({ reddemId, status })=>{
    let url = config.main_url + `tasks/updateReddemm`;
    const response = await fetch(url, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reddemId, status }), 
    }).then((rsp)=>rsp.json()).then((obj)=>{
        return obj;
    })
    return response;
}

export const addNewTaskGroup = async (data) =>{
    let url = config.main_url + "tasks/addTaskGroup";
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then((rsp)=>rsp.json()).then((obj)=>{
        return obj;
    })
    return response;
}

export const getNewTaskGroup = async () =>{
    let url = config.main_url + `tasks/getTaskGroup`;
    const response = await fetch(url, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((rsp)=>rsp.json()).then((obj)=>{
        return obj;
    })
    return response;
}