import react, { useEffect, useState } from 'react'
import QRCode from "react-qr-code"
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

import Mark from '../../assets/mark.png'

import "./index.css"

const QRcodePage = () =>{

    const goTo = useNavigate();

    const [code, setCode] = useState("000000");
    const [userCode, setUserCode] = useState("");


    useEffect(()=>{
        generateCode();
    }, [])

    const generateCode = () =>{
        let s = "";
        for(let i = 0; i<6;i++){
            s = s + Math.floor(Math.random() * 10);
        }
        console.log(s);
        setCode(s);
    }

    const handleCancelButtonClick = () =>{
        goTo("/login");
    }

    const handleEnableButtonClick = () =>{
        console.log(userCode);
        if(userCode === code){
            localStorage.setItem("token","enable");
            goTo("/");
            window.location.reload();
        }
        else{
            generateCode();
            setUserCode("");
        }
    }

    return (
        <div className='qr-code-page'>
            <img src={Mark} alt="" />
            <h2>Two Factor-Authentication</h2>
            <p>To enhance the security of your account, please enter the verification code generated by your Google</p>
            <p>Authenticator app. This code is required in addition to your password to log in to the admin panel.</p>
            <br />
            <br />
            <br />
            <br />
            <div className="qrcode__image">
                <QRCode value={code} size={130} />
            </div>
            <br />
            <br />
            <br />
            <h2>Enter the six-digit code from the application</h2>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem </p>
            <p>Ipsum has been the industry's.</p>
            <input type="text" placeholder='012345' value={userCode} onChange={(e)=>{setUserCode(e.currentTarget.value)}} />
            <div className='button-group'>
                <Button variant="contained" style={{width:"100px", marginTop:"30px"}} onClick={handleEnableButtonClick} disableElevation>
                    Enable
                </Button>
                <Button variant="outlined" onClick={handleCancelButtonClick}>Cancel</Button>
            </div>
        </div>
    )
}

export default QRcodePage