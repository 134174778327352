

import React from "react";
// import './toast.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ErrorToast = (message) => {
  toast.error(`${message}`, {
    closeToast: (
      <button className="custom-close-button__error close__btn__common">
        Close
      </button>
    ),
    // icon: () => <img src="/Images/Toast/error.svg" alt="Error Icon" />,
  });
  return null;
};

export const SuccessToast = (message) => {
  toast.success(`${message}`, {
    closeToast: (
      <button className="custom-close-button__success close__btn__common">
        Close
      </button>
    ),
    // icon: () => <img src="/Images/Toast/success.svg" alt="Success Icon" />,
  });
  return null;
};