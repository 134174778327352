
// import './RedeemRequest.css'
// import * as React from 'react';
// import {
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     Paper,
//     Avatar,
//     IconButton,
// } from '@mui/material';
// import { useState } from 'react';



// const RedeemRequest = () => {
//     const rows1 = [
//         {
//             image: '/Images/redeeemrequest_prof.svg', 
//             username: 'BE Community',
//             wallet: '0xe80512Bf5B5....Cbd3101',
//             chain: 'Solana',
//             amount: '$1,000',
//             action: 'cancel', 
//         },
//         {
//             image: '/Images/redeeemrequest_prof.svg',
//             username: 'BE Community',
//             wallet: '0xe80512Bf5B5....Cbd3101',
//             chain: 'Solana',
//             amount: '$1,000',
//             action: 'check',
//         },
//         {
//             image: '/Images/redeeemrequest_prof.svg',
//             username: 'BE Community',
//             wallet: '0xe80512Bf5B5....Cbd3101',
//             chain: 'Solana',
//             amount: '$1,000',
//             action: 'check',
//         },
//         {
//             image: '/Images/redeeemrequest_prof.svg',
//             username: 'BE Community',
//             wallet: '0xe80512Bf5B5....Cbd3101',
//             chain: 'Solana',
//             amount: '$1,000',
//             action: 'check',
//         },
//         {
//             image: '/Images/redeeemrequest_prof.svg',
//             username: 'BE Community',
//             wallet: '0xe80512Bf5B5....Cbd3101',
//             chain: 'Solana',
//             amount: '$1,000',
//             action: 'check',
//         },
//         {
//             image: '/Images/redeeemrequest_prof.svg',
//             username: 'BE Community',
//             wallet: '0xe80512Bf5B5....Cbd3101',
//             chain: 'Solana',
//             amount: '$1,000',
//             action: 'check',
//         },
//         {
//             image: '/Images/redeeemrequest_prof.svg',
//             username: 'BE Community',
//             wallet: '0xe80512Bf5B5....Cbd3101',
//             chain: 'Solana',
//             amount: '$1,000',
//             action: 'check',
//         },
//         {
//             image: '/Images/redeeemrequest_prof.svg',
//             username: 'BE Community',
//             wallet: '0xe80512Bf5B5....Cbd3101',
//             chain: 'Solana',
//             amount: '$1,000',
//             action: 'check',
//         },
//         {
//             image: '/Images/redeeemrequest_prof.svg',
//             username: 'BE Community',
//             wallet: '0xe80512Bf5B5....Cbd3101',
//             chain: 'Solana',
//             amount: '$1,000',
//             action: 'check',
//         },
//         {
//             image: '/Images/redeeemrequest_prof.svg',
//             username: 'BE Community',
//             image: '/Images/redeeemrequest_prof.svg',
//             chain: 'Solana',
//             amount: '$1,000',
//             action: 'check',
//         },
//         {
//             image: '/Images/redeeemrequest_prof.svg',
//             username: 'BE Community',
//             wallet: '0xe80512Bf5B5....Cbd3101',
//             chain: 'Solana',
//             amount: '$1,000',
//             action: 'check',
//         },
//         {
//             image: '/Images/redeeemrequest_prof.svg',
//             username: 'BE Community',
//             wallet: '0xe80512Bf5B5....Cbd3101',
//             chain: 'Solana',
//             amount: '$1,000',
//             action: 'check',
//         },
//         {
//             image: '/Images/redeeemrequest_prof.svg',
//             username: 'BE Community',
//             wallet: '0xe80512Bf5B5....Cbd3101',
//             chain: 'Solana',
//             amount: '$1,000',
//             action: 'check',
//         },
//         {
//             image: '/Images/redeeemrequest_prof.svg',
//             username: 'BE Community',
//             wallet: '0xe80512Bf5B5....Cbd3101',
//             chain: 'Solana',
//             amount: '$1,000',
//             action: 'check',
//         },
//         // Add more rows as needed
//     ];
//     const [rows, setRows] = useState(rows1);


//     const handleCheckbox = (index) => {
//         const updatedRows = rows.map((row, i) =>
//             i === index
//                 ? { ...row, action: row.action === 'check' ? 'cancel' : 'check' }
//                 : row
//         );
//         setRows(updatedRows);
//     };

//     return (
//         <div className='RedeemRequestMain'>
//             <TableContainer component={Paper} className='tableContainer__' sx={{ backgroundColor: '#090909', border: "2px solid #121212", borderRadius: "10px", padding: "30px" }}>
//                 <Table sx={{ minWidth: 650 }} aria-label="custom table">
//                     <TableHead>
//                         <TableRow>
//                             <TableCell className='RedeemRequest__tableheadtext'>User Image</TableCell>
//                             <TableCell className='RedeemRequest__tableheadtext'>Telegram Username</TableCell>
//                             <TableCell className='RedeemRequest__tableheadtext'>Wallet Address</TableCell>
//                             <TableCell className='RedeemRequest__tableheadtext'>Chain</TableCell>
//                             <TableCell className='RedeemRequest__tableheadtext'>Amount</TableCell>
//                             <TableCell className='RedeemRequest__tableheadtext'>Action</TableCell>
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {rows.map((row, index) => (
//                             <TableRow
//                                 key={index}
//                             >
//                                 <TableCell style={{ borderBottom: "1px solid #121212" }}>
//                                     <Avatar alt={row.username} src={row.image} />
//                                 </TableCell>
//                                 <TableCell style={{ borderBottom: "1px solid #121212" }} className='RedeemRequest__rowText'>{row.username}</TableCell>
//                                 <TableCell style={{ borderBottom: "1px solid #121212" }} className='RedeemRequest__rowText1'>{row.wallet}</TableCell>
//                                 <TableCell style={{ borderBottom: "1px solid #121212" }} className='RedeemRequest__rowText1'>{row.chain}</TableCell>
//                                 <TableCell style={{ borderBottom: "1px solid #121212" }} className='RedeemRequest__rowText2'>{row.amount}</TableCell>
//                                 <TableCell style={{ borderBottom: "1px solid #121212" }} >
//                                     <IconButton onClick={() => handleCheckbox(index)}>
//                                         {row.action === 'check' ? (
//                                             <img src="/Images/tick.svg" alt="Tick" />
//                                         ) : (
//                                             <img src="/Images/cancel.svg" alt="Cancel" />
//                                         )}
//                                     </IconButton>
//                                 </TableCell>
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//             </TableContainer>


//         </div>
//     )
// }

// export default RedeemRequest
