import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Snackbar, Alert } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import img from "../../assets/mark1.png";
import editIcon from "../../assets/edit-svgrepo-com 1.svg";
import deleteicon from "../../assets/bin-with-lid 1.svg";
import "./TaskGroup.css";

import Header from "../TaskPage/header";
import DeleteModal from "../../components/DeleteModal/index.jsx";
import Loading from "../../components/loading";

import { displayNumbers } from "../../lib/tools";

import { getTasks, deleteTask, getNewTaskGroup, deleteTaskGroup } from "../../apis/tasks.js";

const TaskList = [
  {
    img: "taskIcons/Play_Store.png",
    title: "PlayStore",
    description: "Download the Bluemoon App",
    name: 15,
    started_Date: "1/11/2023",
    ended_Date: "24/11/2023",
    id: "34234234234",
    type: 0,
  },
];

const TaskGroup = () => {
  const goTo = useNavigate();

  const [selectedTaskButton, setSelectedTaskButton] = useState(0);
  const [selectedLive, setSelectedLive] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const [taskList, setTaskList] = useState([{}]);

  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [taskGroup, setTaskGroup] = useState();
  const [taskGroupUpdqated,setTasdGroupUpdated]=useState(false)

  const handleNewTaskButtonClick = () => {
    setSelectedTaskButton(0);
  };

  const handleEssentialTaskButtonClick = () => {
    setSelectedTaskButton(1);
  };

  const handleBonusTaskButtonClick = () => {
    setSelectedTaskButton(2);
  };

  const handleLiveButtonClick = () => {
    setSelectedLive(0);
  };

  const handleEndedButtonClick = () => {
    setSelectedLive(1);
  };

  const handleDeleteButtonClick = (itemId) => {
    setOpenDeleteModal(true);
    setCurrentItem(itemId);
  };

  const taskGroupDelete=async(id)=>{

    let res = await deleteTaskGroup(id);
    if (res.success === false) {
      setSnackbarMessage(res.message);
      setTasdGroupUpdated(true)
      setSnackbarStatus("warning");
      setIsOpenSnackbar(true);
    } else {
      setSnackbarMessage("Deleted Successfully!");
      setSnackbarStatus("success");
      setIsOpenSnackbar(true);
      setTasdGroupUpdated(true)
    }

  }


  const handleEditButtonClick = (itemId) => {
    console.log(taskList[itemId]);
    localStorage.setItem("description", taskList[itemId].description);
    localStorage.setItem("task_id", taskList[itemId].id);
    localStorage.setItem("rewards", taskList[itemId].rewards);
    localStorage.setItem("title", taskList[itemId].title);
    localStorage.setItem("link", taskList[itemId].link);
    let tp = taskList[itemId].type === "new" ? 0 : "essential" ? 1 : 2;
    localStorage.setItem("type", tp);
    localStorage.setItem("img", taskList[itemId].img);
    goTo("/EditTaskGroup");
  };

  async function TaskGroups() {
    try {
      const res = await getNewTaskGroup();
      console.log(res);
      setTaskGroup(res?.mission);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    TaskGroups();
  }, [taskGroupUpdqated]);
  function formatDateTime(isoDate) {
    const date = new Date(isoDate);
  
    // Format date as MM/DD/YYYY
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  
    // Format time as HH:MM:SS AM/PM
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // AM/PM format
    });
  
    return `${formattedDate} ${formattedTime}`;
  }
  return (
    // <Layout>
    <>
      {/* <Loading disp={isLoading}/> */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpenSnackbar}
        autoHideDuration={6000}
        onClose={() => {
          setIsOpenSnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setIsOpenSnackbar(false);
          }}
          severity={snackbarStatus}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {/* <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        deleteCurrentItem={() => {
          deleteItem(currentItem);
        }}
      /> */}
      <div style={{paddingTop:"50px"}} className="landing-contain-content">
        <Header group={true} />
        <div className="landing-contain-section">
          {/* <Card
            
            bordered={false}
            style={{
              
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: "10px",
              marginTop:'30px',
              background: 'rgba(0,0,0,1)',
            }}
          >
            <div className="tasks-button">
              <button
                className={selectedTaskButton === 0 ? "selected" : ""}
                onClick={handleNewTaskButtonClick}
              >
             <span  class="text-gradient">New Tasks</span>
              </button>
              <button
                className={selectedTaskButton === 1 ? "selected" : ""}
                onClick={handleEssentialTaskButtonClick}
              >
                <span  class="text-gradient">Essential Tasks</span>
              </button>
              <button
                className={selectedTaskButton === 2 ? "selected" : ""}
                onClick={handleBonusTaskButtonClick}
              >
             <span  class="text-gradient">Bonus Tasks</span>
              </button>
            </div>
            <div className="live-ended">
              <button
                className={selectedLive === 0 ? "selected" : ""}
                onClick={handleLiveButtonClick}
              >
                Live
              </button>
              <button
                className={selectedLive === 1 ? "selected" : ""}
                onClick={handleEndedButtonClick}
              >
                Ended
              </button>
     
            </div>
          </Card> */}
          <TableContainer
            component={Paper}
            style={{
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              backgroundColor: "rgba(0,0,0,1)",
            }}
          >
            <Table
              className="for-border"
              sx={{
                minWidth: 650,
                backgroundColor: "#090909",
                border: "2px solid #121212",
                borderRadius: "10px",
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "rgba(255,255,255,0.5)",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      borderBottom: "1px solid #121212",
                    }}
                    align="center"
                  >
                    Tasks Image
                  </TableCell> */}
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "rgba(255,255,255,0.5)",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      borderBottom: "1px solid #121212",
                    }}
                  >
                    Title / Description
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "rgba(255,255,255,0.5)",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      borderBottom: "1px solid #121212",
                    }}
                  >
                    Name
                  </TableCell>

                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "rgba(255,255,255,0.5)",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      borderBottom: "1px solid #121212",
                    }}
                  >
                    Date Started
                  </TableCell>
                  {/* <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "rgba(255,255,255,0.5)",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      borderBottom: "1px solid #121212",
                    }}
                  >
                    Date Ended
                  </TableCell> */}
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "rgba(255,255,255,0.5)",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      borderBottom: "1px solid #121212 ",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {taskGroup?.map((task, key) => (
                  <TableRow key={key}>
                    <TableCell className="neechykrna">
                      <h4
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#FFFFFF",
                          lineHeight: "24px",
                          margin: "0px",
                        }}
                      >
                        {task?.groupName}
                      </h4>
                      <p
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "rgba(255,255,255,0.5)",
                          lineHeight: "21px",
                          margin: "0px",
                        }}
                      >
                        {task?.groupDescription}
                      </p>
                    </TableCell>
                    <TableCell
                      className="neechykrna"
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#FFFFFF",
                        lineHeight: "21px",
                      }}
                    >
                     {task?.groupName}
                    </TableCell>

                    <TableCell
                      className="neechykrna"
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "rgba(255,255,255,0.3)",
                        lineHeight: "21px",
                      }}
                    >
                      {formatDateTime(task.createdAt)}
                    </TableCell>
                    {/* <TableCell
                      className="neechykrna"
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "rgba(255,255,255,0.3)",
                        lineHeight: "21px",
                      }}
                    >
                      {selectedLive === 1 ? task.ended_Date : ""}
                    </TableCell> */}
                    <TableCell
                      className="neechykrna"
                      style={{ padding: "2px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          position: "relative",
                          right: "10px",
                        }}
                      >
                        <IconButton
                          aria-label="edit"
                          // onClick={() => {
                          //   handleEditButtonClick(key);
                          // }}
                        >
                          <img
                            src={editIcon}
                            alt="edit"
                            style={{ width: "22px", height: "22px" }}
                          />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            taskGroupDelete(task._id);
                          }}
                        >
                          <img
                            src={deleteicon}
                            alt="delete"
                            style={{ width: "22px", height: "22px" }}
                          />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
    // </Layout>
  );
};

export default TaskGroup;
