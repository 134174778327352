// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .linear-gradient-button {
    border-width: 0;
    position: relative;
    overflow: hidden;
    border-radius: 60px;
    height:45px;
    width:145px
  }
  
  .linear-gradient-button > span {
    position: relative;
    z-index: 1;
  }
  
  .linear-gradient-button::before {
    content: '';
    background: linear-gradient(90deg, #0B6719 0%, #2AD72D 100%);
    position: absolute;
    inset: 0;
    opacity: 1;
    transition: all 0.3s;
    border-radius: inherit;
    z-index: 0;
  } */

/* .linear-gradient-button:hover::before {
    opacity: 0;
  } */

.topbutton-header {
  width: 145px;
  height: 45px;
  padding: 13px 38px 15px 20px;
  border-radius: 60px;
  background: linear-gradient(92.23deg, #ff00d9 -19.69%, #6c4efe 100%);

  gap: 12px !important;
  border: none;
}
.topbutton-header:hover {
  background: linear-gradient(92.23deg, #ff00d9 -19.69%, #6c4efe 100%) !important;
}
.button-text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  text-align: left;
  margin: 0px !important;
}

@media (max-width: 430px) {
  .topbutton-header {
    width: 127px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Button1/index.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;KAuBK;;AAEL;;KAEK;;AAEL;EACE,YAAY;EACZ,YAAY;EACZ,4BAA4B;EAC5B,mBAAmB;EACnB,oEAAoE;;EAEpE,oBAAoB;EACpB,YAAY;AACd;AACA;EACE,+EAA+E;AACjF;AACA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":["/* .linear-gradient-button {\n    border-width: 0;\n    position: relative;\n    overflow: hidden;\n    border-radius: 60px;\n    height:45px;\n    width:145px\n  }\n  \n  .linear-gradient-button > span {\n    position: relative;\n    z-index: 1;\n  }\n  \n  .linear-gradient-button::before {\n    content: '';\n    background: linear-gradient(90deg, #0B6719 0%, #2AD72D 100%);\n    position: absolute;\n    inset: 0;\n    opacity: 1;\n    transition: all 0.3s;\n    border-radius: inherit;\n    z-index: 0;\n  } */\n\n/* .linear-gradient-button:hover::before {\n    opacity: 0;\n  } */\n\n.topbutton-header {\n  width: 145px;\n  height: 45px;\n  padding: 13px 38px 15px 20px;\n  border-radius: 60px;\n  background: linear-gradient(92.23deg, #ff00d9 -19.69%, #6c4efe 100%);\n\n  gap: 12px !important;\n  border: none;\n}\n.topbutton-header:hover {\n  background: linear-gradient(92.23deg, #ff00d9 -19.69%, #6c4efe 100%) !important;\n}\n.button-text {\n  font-family: Montserrat;\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 17.07px;\n  text-align: left;\n  margin: 0px !important;\n}\n\n@media (max-width: 430px) {\n  .topbutton-header {\n    width: 127px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
