// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/FontFamily/Roboto-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/FontFamily/Montserrat-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../public/FontFamily/Montserrat-BoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../public/FontFamily/Montserrat-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@font-face {
  font-family: "Roboto";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "MontserratBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
body, h1,h2,h3,h4,p,a {
  font-family: "Roboto", sans-serif;
}

/* *{
  background-color: #0E0E0E ;
} */

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;;;GAKG;;AAEH;;;GAGG;AACH;EACE,qBAAqB;EACrB,+DAAqE;AACvE;AACA;EACE,yBAAyB;EACzB,+DAAyE;AAC3E;AACA;EACE,6BAA6B;EAC7B,+DAA6E;AAC/E;AACA;EACE,8BAA8B;EAC9B,+DAAuE;AACzE;AACA;EACE,iCAAiC;AACnC;;AAEA;;GAEG","sourcesContent":["/* body {\n  margin: 0;\n  font-family: \"Roboto\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n} */\n\n/* code {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n} */\n@font-face {\n  font-family: \"Roboto\";\n  src: url('../public/FontFamily/Roboto-Medium.ttf') format(\"truetype\");\n}\n@font-face {\n  font-family: \"Montserrat\";\n  src: url('../public/FontFamily/Montserrat-Medium.ttf') format(\"truetype\");\n}\n@font-face {\n  font-family: \"MontserratBold\";\n  src: url('../public/FontFamily/Montserrat-BoldItalic.ttf') format(\"truetype\");\n}\n@font-face {\n  font-family: \"Montserrat-Bold\";\n  src: url('../public/FontFamily/Montserrat-Bold.ttf') format(\"truetype\");\n}\nbody, h1,h2,h3,h4,p,a {\n  font-family: \"Roboto\", sans-serif;\n}\n\n/* *{\n  background-color: #0E0E0E ;\n} */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
