import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import "./Anti.css";

import TaskPage from "./pages/TaskPage/index";
import EmptyPage from "./pages/emptyPage/index";
import EditTaskPage from "./pages/EditTaskPage";
import LoginPage from "./pages/loginPage";
import QRcodePage from "./pages/QRcodePage";

import Layout from "./Layouts";
import Navbar from "./Layouts/navbar";
import AddNewTask from "./pages/AddNewTask";
import RedeemRequest from "./pages/RedeemRequests/RedeemRequest";
import ShopPoints from "./pages/ShopPoints/ShopPoints";
import Usdt from "./pages/USDT/Usdt";
import RaffleTicket from "./pages/RaffleTicket/RaffleTicket";
import { ToastContainer } from "react-toastify";
import TaskGroup from "./pages/TaskGroup/TaskGroup";
import EditTaskGroup from "./pages/EditTaskGroup/EditTaskGroup";
import AddTaskGroup from "./pages/AddTaskGroup/AddTaskGroup";

function App() {
  const [token, setToken] = useState(false);

  useEffect(() => {
    let tkn = localStorage.getItem("token");
    if (tkn) setToken(true);
  }, []);
  return (
    // !token ?
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="light"
      />

      <Routes>
        {/* <Route path="/" element={<EmptyPage />} /> */}
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/edit_task"
          element={
            <Layout>
              <EditTaskPage />
            </Layout>
          }
        />
        <Route
          path="/task"
          element={
            <Layout>
              <TaskPage />
            </Layout>
          }
        />
        <Route
          path="/empty"
          element={
            <Layout>
              <EmptyPage />
            </Layout>
          }
        />
        {/* <Route path="/addTask" element={<AddNewTask />} /> */}
        <Route
          path="/RedeemRequest"
          element={
            <Layout>
              <RedeemRequest />
            </Layout>
          }
        />
        <Route
          path="/ShopPoints"
          element={
            <Layout>
              <ShopPoints />
            </Layout>
          }
        />
        <Route
          path="/Usdt"
          element={
            <Layout>
              <Usdt />
            </Layout>
          }
        />
        <Route
          path="/RaffleTicket"
          element={
            <Layout>
              <RaffleTicket />
            </Layout>
          }
        />
        <Route
          path="/addTask"
          element={
            <Layout>
              <AddNewTask />
            </Layout>
          }
        />
        <Route
          path="/TaskGroup"
          element={
            <Layout>
              <TaskGroup />
            </Layout>
          }
        />
        <Route
          path="/EditTaskGroup"
          element={
            <Layout>
              <EditTaskGroup />
            </Layout>
          }
        />

        <Route
          path="/AddTaskGroup"
          element={
            <Layout>
              <AddTaskGroup />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
