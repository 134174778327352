import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { DropzoneArea } from "material-ui-dropzone";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import axios from "axios";
import config from "../../config";

import Loading from "../../components/loading";

import { addNewTask, getNewTaskGroup, updateTask } from "../../apis/tasks";

import "./index.css";

const AddNewTask = () => {
  const goTo = useNavigate();

  // States for handleing countries downdrop search fiels
  const [country, setCountry] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([{}]);
  const [apiCountries, setApiCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  //////////////////////////

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [rewards, setRewards] = useState(0);
  const [link, setLink] = useState("");
  const [type, setType] = useState("");
  const [img, setImg] = useState("");
  const [taskId, setTaskId] = useState("");
  const [imageSource, setImageSource] = useState();
  const [taskGroup, setTaskGroup] = useState();
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({ id: "", groupName: "" });

  const handleCancelButtonClick = () => {
    goTo("/task");
  };

  const handlePublishButtonClick = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", imageSource);
    try {
      const response = await axios
        .post(`${config.main_url}upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setSnackbarMessage("Uploaded Successfully!");
          setSnackbarStatus("success");
          setIsOpenSnackbar(true);
          const run = async () => {
            let res = await addNewTask({
              title,
              description,
              rewards,
              link,
              type: selectedGroup?.groupName,
              img: response.data.data,
              taskGoup: selectedGroup?.id,
              country: apiCountries,
            });
            if (res.success === false) {
              setSnackbarMessage(res.message);
              setSnackbarStatus("warning");
              setIsOpenSnackbar(true);
            } else {
              console.log(res);
              setTitle("");
              setDescription("");
              setRewards(0);
              setLink("");
              setType(0);
              setImg("");
              setSnackbarMessage(res.message);
              setSnackbarStatus("success");
              handleCancelButtonClick();
              setIsOpenSnackbar(true);
            }
          };

          run();
        });
    } catch (error) {
      setSnackbarMessage("Upload faild!");
      setSnackbarStatus("warning");
      setIsOpenSnackbar(true);
    }

    setIsLoading(false);
  };

  const handleChange = (event) => {
    const selectedItem = taskGroup?.find(
      (item) => item.groupName === event.target.value
    );
    setSelectedGroup({
      id: selectedItem?._id,
      groupName: selectedItem.groupName,
    });
  };
  console.log(selectedGroup);

  async function TaskGroups() {
    try {
      const res = await getNewTaskGroup();
      console.log(res);
      setTaskGroup(res?.mission);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    TaskGroups();
  }, []);
  // const handleChange = (event) => {
  //   console.log("this is event", event, selectedId);
  //   setType(event.target.value); // Update the state when an option is selected
  // };

  // Handlelling Countrries searching and inputs etc
  useEffect(() => {
    // Fetch countries with names and flags from an API
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryData = response.data.map((country) => ({
          name: country.name.common,
          flag: country.flags.png, // URL for the flag image
        }));
        setCountries(countryData);
      })
      .catch((error) => console.error("Error fetching country data:", error));
  }, []);

  const handleInputChange = (e) => {
    const userInput = e.target.value.replace(/[^a-zA-Z\s]/g, "");

    setCountry(userInput);

    if (userInput) {
      const filtered = countries.filter((c) =>
        c.name.toLowerCase().startsWith(userInput.toLowerCase())
      );
      setFilteredCountries(filtered);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleCountrySelect = (selectedCountry) => {
    setShowDropdown(false);
    // setSelectedCountries(selectedCountries.a);

    setSelectedCountries((prevCountries) => {
      const newCountry = {
        name: selectedCountry.name,
        flag: selectedCountry.flag,
      };
      // Check if the country already exists based on the name
      const exists = prevCountries.some(
        (country) => country.name === newCountry.name
      );

      // Only add if it doesn't already exist
      if (!exists) {
        return [...prevCountries, newCountry];
      }
      // Return the existing list unchanged if the country already exists
      return prevCountries;
    });

    // check countries for api include the name of country or not
    if (!apiCountries.includes(selectedCountry.name)) {
      setApiCountries([...apiCountries, selectedCountry.name]);
    }

    setCountry("");
  };

  const handleRemoveCountry = (countryName) => {
    setSelectedCountries((prevCountries) =>
      prevCountries.filter((c) => c.name !== countryName)
    );

    if (apiCountries.length > 1) {
      // Remove from apiCountries
      setApiCountries((prevApiCountries) =>
        prevApiCountries.filter((c) => c !== countryName)
      );
    } else {
      setApiCountries([]);
    }
  };

  useEffect(() => {
    console.log("APi countries are as===> ", apiCountries);
  }, [apiCountries]);

  return (
    <>
      <Loading disp={isLoading} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpenSnackbar}
        autoHideDuration={6000}
        onClose={() => {
          setIsOpenSnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setIsOpenSnackbar(false);
          }}
          severity={snackbarStatus}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {/* <Layout> */}
      <div style={{ backgroundColor: "black" }}>
        <div className="edit-task-page">
          <div className="edit-task-page-header">
            <h2
              style={{
                fontFamily: "Montserrat",
                fontSize: "24px",
                fontWeight: "700",
                margin: "0px",
                color: "white",
                lineHeight: "29.26px",
              }}
            >
              Reward /
            </h2>
            <h4
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "700",
                color: "rgba(255,255,255,0.5)",
                marginLeft: "5px",
                margin: "0px",
              }}
            >
              Add Task
            </h4>
          </div>
          <div
            style={{
              border: "2px solid #121212",
              borderRadius: "10px",
              marginTop: "24px",
            }}
          >
            <div className="edit-task-page-body">
              <div className="edit-task-page-body-left">
                <div style={{ marginBottom: "30px" }}>
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#FFFFFF",
                      lineHeight: "19.5px",
                    }}
                    htmlFor="task_title"
                  >
                    Task Title
                  </label>
                  <input
                    type="text"
                    className="task_title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.currentTarget.value);
                    }}
                    name="task_title"
                    placeholder="Enter title of the reward"
                  />
                </div>
                <div style={{ marginBottom: "33px" }}>
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#FFFFFF",
                      lineHeight: "19.5px",
                    }}
                    htmlFor="task_description"
                  >
                    Task Description
                  </label>

                  <input
                    type="text"
                    className="task_title"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.currentTarget.value);
                    }}
                    name="task_description"
                    rows="10"
                    cols="50"
                    placeholder="Enter description of the reward"
                  ></input>

                  <style>
                    {`
      #task_description::placeholder {
        color: rbga(255,255,255,0.7);
      }
    `}
                  </style>
                </div>
                <div style={{ marginBottom: "33px" }}>
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#FFFFFF",
                      lineHeight: "19.5px",
                    }}
                    htmlFor="reward_amount"
                  >
                    Choose Orbitz Reward Amount
                  </label>
                  <input
                    className="input-number1"
                    type="number"
                    value={rewards}
                    onChange={(e) => setRewards(e.currentTarget.value)}
                    id="reward_amount"
                    name="reward_amount"
                    placeholder="Select Orbitz Reward Amount"
                  />
                </div>
                {/* Country selection field is here */}
                <div className="Admin_Country__input-txt">
                  <label>Country</label>
                  <div className="Admin_Country_Search_div">
                    <div className="Country_and_Input_Section">
                      {/* Div having all countries */}
                      <div className="Country_List_Input">
                        {selectedCountries.map((c, index) => {
                          return (
                            c.name && (
                              <div
                                className="Main_Country_Div"
                                onClick={() => handleRemoveCountry(c.name)}
                              >
                                {c.name}
                                {c.flag && (
                                  <img
                                    src={c.flag}
                                    alt={`${c.name} flag`}
                                    style={{
                                      width: "23px",
                                      height: "12px",
                                    }}
                                  />
                                )}
                              </div>
                            )
                          );
                        })}
                      </div>
                      {/* Input to enter the countries */}
                      <input
                        placeholder="Add Country"
                        value={country}
                        onChange={handleInputChange}
                      />
                    </div>
                    <img src="/Images/Search/search_Icon.svg" alt="" />
                  </div>
                  {showDropdown && (
                    <ul className="dropdown">
                      {filteredCountries.map((c, index) => (
                        <li
                          key={index}
                          onClick={() => handleCountrySelect(c)}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={c.flag}
                            alt={`${c.name} flag`}
                            style={{ width: "20px", marginRight: "10px" }}
                          />
                          {c.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {/* ////////////////////////////////////// */}
                <div style={{ marginBottom: "36px" }}>
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#FFFFFF",
                      lineHeight: "19.5px",
                    }}
                    htmlFor="url_link"
                  >
                    Task URL Link
                  </label>
                  <input
                    className="input-number1"
                    type="url"
                    id="url_link"
                    value={link}
                    onChange={(e) => {
                      setLink(e.currentTarget.value);
                    }}
                    name="url_link"
                    placeholder="Input Task URL Link"
                  />
                </div>
                <div style={{ marginBottom: "0px" }}>
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#FFFFFF",
                      lineHeight: "19.5px",
                    }}
                    htmlFor="task_group"
                  >
                    Choose Task Group
                  </label>
                  <Select
                    id="task_group"
                    value={selectedGroup.groupName} // Display the groupName
                    onChange={handleChange} // Handle selection change
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      color: "white",
                      ".MuiSelect-icon": { color: "white" },
                      backgroundColor: "#333",
                    }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select Group</em>; // Placeholder when nothing is selected
                      }
                      return selected; // Display the selected groupName
                    }}
                  >
                    {/* Dynamic menu items from taskGroup */}
                    {taskGroup?.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.groupName} // Display value as groupName
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#FFFFFF",
                          lineHeight: "19.5px",
                          background: "transparent",
                          borderBottom: "1px solid rgba(18,18,18,1)",
                        }}
                      >
                        {item.groupName}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <br />
                <div className="edit-page-left-button-group">
                  <Button
                    variant="contained"
                    onClick={handlePublishButtonClick}
                    disableElevation
                  >
                    {taskId.length ? "Publish" : "Publish"}
                  </Button>
                  <Button variant="outlined" onClick={handleCancelButtonClick}>
                    Cancel
                  </Button>
                </div>
              </div>
              <div className="edit-task-page-body-right">
                <label
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#FFFFFF",
                  }}
                  htmlFor="upload_image"
                >
                  Upload Task Image
                </label>
                <label className="imagesize">Image size: 52x52</label>
                <DropzoneArea
                  id="upload_image"
                  filesLimit={1}
                  acceptedFiles={["image/*"]}
                  maxFileSize={5000000}
                  showAlerts={["error", "info"]}
                  dropzoneText="Drag & Drop or "
                  value={img}
                  onChange={(files) => {
                    setImageSource(files[0]);
                  }}
                  onDropRejected={(files) => {
                    alert("File rejected: " + files[0].name);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Layout> */}
    </>
  );
};

export default AddNewTask;
